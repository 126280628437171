import { NgModule } from '@angular/core';
import {SharedModule} from "../Shared/shared.module";
import {OffenesTicketComponent} from "./offenes-ticket.component";
import {OffenesTicketRoutingModule} from "./offenes-ticket-routing.module";


@NgModule({
  declarations: [
    OffenesTicketComponent,
  ],
  imports: [
    OffenesTicketRoutingModule,
    SharedModule,
  ]
})
export class OffenesTicketModule { }
