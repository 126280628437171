import {Component, OnInit} from '@angular/core';
import {SelectTabService} from "../Service/select-tab.service";
import {FormBuilder} from "@angular/forms";

export interface Request {
  source: string;
  destination: string;
  service:string;

}


@Component({
  selector: 'app-offenes-ticket',
  templateUrl: './offenes-ticket.component.html',
  styleUrl: './offenes-ticket.component.scss'
})
export class OffenesTicketComponent implements OnInit {
  selectedTab: number = 0
  basisFields :any[] = []
  isdisable : boolean = true;

  constructor(private tabservice: SelectTabService, private fb: FormBuilder,) {
  }

  displayedColumns: string[] = ['Source', 'Destinations', 'Service'];
  templates:any[] = [];


  ngOnInit(): void {
    this.tabservice.tab$.subscribe(tabIndex => {
      this.selectedTab = tabIndex.tabindex;
      this.disableTab(this.selectedTab);
    });
    this.tabservice.currentData.subscribe(data =>{
      if (data){
        this.basisFields = data.data.fields;
        this.templates = data.data.originalTraffic;
      }
    })
  }

  getFieldValue(label: string): string {
    const field = this.basisFields.find(f => f.name === label);
    return field ? field.values : '';
  }

  getFieldTableValue(element: any, field: string): string {
    if (element[field] && element[field].items && element[field].items.length > 0) {
      return element[field].items.map((item: { value: any; }) => item.value).join(', ');
    }
    return 'N/A';
  }


  disableTab(tabIndex: number): boolean {
    return tabIndex > this.selectedTab;
  }





}
