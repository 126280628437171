<mat-card>
  <mat-tab-group [(selectedIndex)]="selectedTab" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">

    <!-- Tab 1 -->
    <mat-tab [disabled]="disableTab(0)">
      <ng-template mat-tab-label>
        <mat-icon>outlined_flag</mat-icon>
      </ng-template>
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="sharedTabContent"></ng-container>
      </ng-template>
    </mat-tab>

    <!-- Tab 2 -->
    <mat-tab label="Plan" [disabled]="disableTab(1)">
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="sharedTabContent"></ng-container>
      </ng-template>
    </mat-tab>

    <!-- Tab 3 -->
    <mat-tab label="Approve" [disabled]="disableTab(2)">
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="sharedTabContent"></ng-container>
      </ng-template>
    </mat-tab>

    <!-- Tab 4 -->
    <mat-tab label="Review" [disabled]="disableTab(3)">
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="sharedTabContent"></ng-container>
      </ng-template>
    </mat-tab>

    <!-- Tab 5 -->
    <mat-tab label="Implement" [disabled]="disableTab(4)">
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="sharedTabContent"></ng-container>
      </ng-template>
    </mat-tab>

    <!-- Validate Tab with Two Buttons -->
    <mat-tab label="Validate" [disabled]="disableTab(5)">
      <ng-template matTabContent>
        <div class="button-group">
          <button mat-fab extended="">
            Change Works
          </button>
          <button mat-fab extended="">
            Change Does Not Work
          </button>
        </div>
        <ng-container *ngTemplateOutlet="sharedTabContent"></ng-container>
      </ng-template>
    </mat-tab>

    <!-- Tab 7 -->
    <mat-tab label="Match" [disabled]="disableTab(6)"  >
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="sharedTabContent"></ng-container>
      </ng-template>
    </mat-tab>

    <!-- Tab with Flag Icon -->
    <mat-tab [disabled]="disableTab(7)">
      <ng-template mat-tab-label>
        <mat-icon>flag</mat-icon>
      </ng-template>
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="sharedTabContent"></ng-container>
      </ng-template>
    </mat-tab>

  </mat-tab-group>

  <!-- Shared Tab Content Template -->
  <ng-template #sharedTabContent>
    <mat-card-content>

      <!-- Burger Menu for All Tabs -->
      <div class="burger-menu">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="menu">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>Comment</button>
          <button mat-menu-item>Duplicate</button>
        </mat-menu>
      </div>


        <!-- Basis Section -->
        <div>
          <p style="color: blue;">View Request Form</p>
        </div>
        <div class="item">
          <div class="field">
            <mat-label class="label">Subject:</mat-label>
            <span class="value">{{ getFieldValue('Subject') }}</span>
          </div>

          <div class="field">
            <mat-label class="label">Owner:</mat-label>
            <span class="value">{{ getFieldValue('Owner') }}</span>
          </div>

          <div class="field">
            <mat-label class="label">Status:</mat-label>
            <span class="value">{{ getFieldValue('status') }}</span>
          </div>

          <div class="field">
            <mat-label class="label">Created:</mat-label>
            <span class="value">{{ getFieldValue('Created') }}</span>
          </div>

          <div class="field">
            <mat-label class="label">Requestor:</mat-label>
            <span class="value">{{ getFieldValue('Requestor') }}</span>
          </div>

          <div class="field">
            <mat-label class="label">Updated:</mat-label>
            <span class="value">{{ getFieldValue('LastUpdated') }}</span>
          </div>

          <div class="field">
            <mat-label class="label">Due:</mat-label>
            <span class="value">{{ getFieldValue('Due') }}</span>
          </div>

          <div class="field">
            <mat-label class="label">Closed:</mat-label>
            <span class="value">{{ getFieldValue('Closed') }}</span>
          </div>
        </div>


        <!-- General Section -->
        <br>
        <h2>General</h2>
        <div class="item">
          <div class="field">
            <mat-label class="label">Expires:</mat-label>
            <span class="value">{{ getFieldValue('Expire') }}</span>
          </div>

          <div class="field">
            <mat-label class="label">Owning Role:</mat-label>
            <span class="value">{{ 'Network' }}</span>
          </div>
        </div>

      <div class="content">
        <h4 class="table-header">Original Request</h4>
        <mat-table [dataSource]="templates" class="mat-elevation-z8">
          <ng-container matColumnDef="Source">
            <mat-header-cell *matHeaderCellDef> Source </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{getFieldTableValue(element, 'source')}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Destinations">
            <mat-header-cell *matHeaderCellDef> Destination </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{getFieldTableValue(element, 'destination')}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Service">
            <mat-header-cell *matHeaderCellDef> Service </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{getFieldTableValue(element, 'service')}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </ng-template>
</mat-card>
