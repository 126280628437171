import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {OffenesTicketComponent} from "./offenes-ticket.component";

const routes: Routes = [
  { path: '', component: OffenesTicketComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OffenesTicketRoutingModule { }
